import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import parse from "html-react-parser"
import Seo from "../components/seo"

const Urdu = ({ data: { urdu } }) => {
  const {
    title,
    content,
    excerpt,
    uri,
    date,
    databaseId,
    youtubeLink,
    smartcrawlSeo,
    bookSchema,
  } = urdu
  const ytLink = youtubeLink.youtubeVideoId
  const youtube = "https://youtube.com/embed/".concat(
    ytLink,
    "?cc_load_policy=1&amp;cc_lang_pref=ur"
  )
  const featuredImage = {
    src: urdu.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: urdu.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      <Seo
        title={smartcrawlSeo?.title}
        description={smartcrawlSeo?.metaDesc}
        ogTitle={smartcrawlSeo?.opengraphTitle}
        ogDescription={smartcrawlSeo?.opengraphDescription}
        twTitle={smartcrawlSeo?.twitterTitle}
        twDescription={smartcrawlSeo?.twitterDescription}
        featuredImage={featuredImage?.node?.mediaItemUrl}
        ogImage={smartcrawlSeo?.opengraphImage?.mediaItemUrl}
        twImage={smartcrawlSeo?.twitterImage?.mediaItemUrl}
      />
      {ytLink == null && (
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org/",
                "@type": "DataFeed",
                "name": "${bookSchema?.englishtitle}",
                "description": "${excerpt}",
                "dataFeedElement": [
                  {
                    "@id": "${bookSchema?.englishlink}",
                    "@type": "Book",
                    ${urdu.authors.nodes.map(
                      node =>
                        `"author": {
                          "@type": "Person",
                          "name": "${node.name}",
                          "sameAs": "${node.authorSchema.schemalink}"
                        }`
                    )},
                    "inLanguage": "en",
                    "name": "${bookSchema?.englishtitle}",
                    "sameAs": "${bookSchema?.englishlink}",
                    "isbn": "${bookSchema?.isbn}",
                    "datePublished": "${bookSchema?.englishpublicationdate}",
                    "workTranslation": {
                        "@type": "Book",
                        "@id": "${bookSchema?.englishlink}"
                    }
                  },
                  {
                    "@type": "Book",
                    "workExample": {
                      ${urdu.authors.nodes.map(
                        node =>
                          `"author": {
                            "@type": "Person",
                            "name": "${node.name}",
                            "sameAs": "${node.authorSchema.schemalink}"
                          }`
                      )},
                      "inLanguage": "ar",
                      "name": "${title}",
                      "image": {
                          "@type": "ImageObject",
                          "url": "${featuredImage?.node?.mediaItemUrl}"
                      },
                      "translationOfWork": {
                          "@id": "${bookSchema?.englishlink}"
                      },
                      "bookEdition": "Urdu Translation",
                      "datePublished": "${date}",
                      "abstract": "${excerpt}",
                      "bookFormat": "http://schema.org/EBook",
                      "@id": "https://translationsproject.org${uri}",
                      "potentialAction": {
                        "@type": "ReadAction",
                        "target": {
                          "@type": "EntryPoint",
                          "urlTemplate": "https://translationsproject.org${uri}",
                          "actionPlatform": [
                            "http://schema.org/DesktopWebPlatform",
                            "http://schema.org/AndroidPlatform",
                            "http://schema.org/IOSPlatform"
                          ]
                        }
                      }
                    }
                  }
                ]
              }
            `}
          </script>
        </Helmet>
      )}
      <article
        id={"post-" + databaseId}
        className="single urdu type-urdu hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">{parse(title)}</h1>
        </header>
        <div lang="ar" className="entry-content clearfix">
          {ytLink && (
            <>
              <iframe
                loading="lazy"
                title={title}
                src={youtube}
                style={{ width: "1200", height: "675" }}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="youtube-video"
              />
              {content && parse(content)}
            </>
          )}
          {ytLink == null && featuredImage.src && (
            <div className="mb-5 wp-block-columns urdu flex-row-reverse">
              <div
                className="wp-block-column is-vertically-aligned-center"
                style={{ flexBasis: "25%" }}
              >
                <GatsbyImage
                  loading="lazy"
                  layout="fullWidth"
                  placeholder="blurred"
                  image={featuredImage.src}
                  className="book-covers shadow"
                  alt={featuredImage.alt}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  formats={"webp"}
                />
              </div>
              <div
                className="wp-block-column is-vertically-aligned-center"
                style={{ flexBasis: "75%" }}
              >
                <section itemProp="description">{parse(content)}</section>
              </div>
            </div>
          )}
          <hr className="wp-block-separator is-style-wide" />
          <div className="wp-block-buttons aligncenter">
            <div className="wp-block-button">
              <Link
                to="/urdu/"
                className="wp-block-button__link has-background has-cfi-orange-background-color"
              >
                مزید اردو کتابیں اور ویڈیوز دیکھیں۔
              </Link>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Urdu

export const pageQuery = graphql`
  query GET_URDU($id: String!) {
    urdu: wpUrdu(id: { eq: $id }) {
      title
      content
      excerpt
      uri
      databaseId
      youtubeLink {
        youtubeVideoId
      }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      bookSchema {
        englishlink
        englishpublicationdate
        englishtitle
        isbn
      }
      authors {
        nodes {
          authorSchema {
            schemalink
          }
          name
        }
      }
      date
      modified
    }
  }
`
